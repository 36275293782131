import { useMediaQuery } from "@mui/material";
import Alert from "@mui/material/Alert";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/material/styles";
import {
  calculatePrependedDays,
  differenceInUnits,
  getCurrentTimezoneDate,
} from "core/utils/dateHandler";
import get from "lodash/get";
import { AccountContext } from "modules/Account/context";
import {
  InteractionsQuery,
  InteractionStatKey,
  InteractionStatLabel,
} from "modules/Interaction/models";
import InteractionService from "modules/Interaction/services";
import { ReportContext } from "modules/Report/context";
import { ReportNames } from "modules/Report/models";
import {
  createDateRange,
  getDateChunks,
  getDateLabels,
  getDateRangeQuery,
} from "modules/Report/utils";
import React, { useCallback, useContext, useMemo } from "react";
import { useQuery } from "react-query";
import ReportCompareItem from "../ReportCompareItem";
import ReportCountItem from "../ReportCountItem";
import ReportElement from "../ReportElement";

const TOTAL = 8;

const getValues = (input: number[], prependedDays: number): number[] => {
  const prependedArray = new Array(prependedDays).fill(0);
  return prependedArray.concat(input);
};

export interface ReportListProps {
  defaultQueryString?: string;
}

const ReportList: React.FC<ReportListProps> = ({ defaultQueryString = "" }) => {
  const { account } = useContext(AccountContext);
  const {
    report: { currentRange: range },
  } = useContext(ReportContext);
  const theme = useTheme();

  const isXl = useMediaQuery(theme.breakpoints.only("xl"));
  const isLg = useMediaQuery(theme.breakpoints.only("lg"));
  const isMd = useMediaQuery(theme.breakpoints.only("md"));
  const isSm = useMediaQuery(theme.breakpoints.only("sm"));
  const isXs = useMediaQuery(theme.breakpoints.only("xs"));

  const dateRange = useMemo(
    () => (range ? createDateRange(range) : undefined),
    [range]
  );

  const queryString = useMemo(
    () => (dateRange ? getDateRangeQuery(dateRange) : ""),
    [dateRange]
  );

  const dateProps = useMemo(() => {
    if (!dateRange)
      return {
        labels: [""],
        chunks: [
          { start: getCurrentTimezoneDate(), end: getCurrentTimezoneDate() },
        ],
        diff: 0,
      };

    const diff = differenceInUnits(dateRange.end, dateRange.start, "d");

    const newChunks = getDateChunks(dateRange, diff);
    const newLabels = getDateLabels(newChunks, diff);

    return { chunks: newChunks, labels: newLabels, diff };
  }, [dateRange]);

  const itemsPerLine = useMemo(() => {
    if (isXl || isLg) return 4;
    if (isMd || isSm) return 3;
    if (isXs) return 1;
    return 2; // default
  }, [isXl, isLg, isMd, isSm, isXs]);

  const fetchAccountStats = useCallback(async () => {
    const { data } = await InteractionService.fetchAccountStats(
      account.id,
      queryString
    );
    return data;
  }, [account.id, queryString]);

  const { data, isLoading, error } = useQuery(
    [InteractionsQuery.account_stats, account.id, queryString],
    fetchAccountStats,
    {
      enabled: !!account.id && !!queryString,
      staleTime: 60 * 1000, // 1 minute
    }
  );

  const prependedDays = useMemo(
    () => calculatePrependedDays(dateRange?.start, data?.date_range?.first),
    [dateRange?.start, data?.date_range?.first]
  );

  const getItemValues = (key: string) =>
    getValues(get(data, `${key}.chunks`), prependedDays);

  if (!account?.time_zone || !queryString) return null;
  if (error) return <Alert severity="error">{String(error)}</Alert>;

  return (
    <Grid container>
      <ReportCompareItem
        items={[
          {
            key: InteractionStatKey.invite_sent,
            count: get(data, `${InteractionStatKey.invite_sent}.total`) || 0,
            values: getItemValues(InteractionStatKey.invite_sent),
          },
          {
            key: InteractionStatKey.invite_accepted,
            count:
              get(data, `${InteractionStatKey.invite_accepted}.total`) || 0,
            values: getItemValues(InteractionStatKey.invite_accepted),
          },
        ]}
        DateProps={dateProps}
        compareLabel="Acceptance rate"
        indexes={[0, 1, 2]}
        total={TOTAL}
        isLoading={isLoading}
        itemsPerLine={itemsPerLine}
      />
      <ReportCompareItem
        items={[
          {
            key: InteractionStatKey.msg_sent,
            count: get(data, `${InteractionStatKey.msg_sent}.total`) || 0,
            values: getItemValues(InteractionStatKey.msg_sent),
          },
          {
            key: InteractionStatKey.replies,
            count: get(data, `${InteractionStatKey.replies}.total`) || 0,
            values: getItemValues(InteractionStatKey.replies),
          },
        ]}
        DateProps={dateProps}
        compareLabel="Reply rate"
        indexes={[3, 4, 5]}
        total={TOTAL}
        isLoading={isLoading}
        itemsPerLine={itemsPerLine}
      />
      <ReportElement
        key={InteractionStatKey.profile_visits}
        label={InteractionStatLabel[InteractionStatKey.profile_visits]}
        index={6}
        total={TOTAL}
        count={get(data, `${InteractionStatKey.profile_visits}.total`) || 0}
        values={getItemValues(InteractionStatKey.profile_visits)}
        isLoading={isLoading}
        DateProps={dateProps}
        itemsPerLine={itemsPerLine}
      />
      <ReportCountItem
        key={ReportNames.pending}
        index={7}
        total={TOTAL}
        label="Invitations pending"
        name={ReportNames.pending}
        DateProps={dateProps}
        QueryProps={{
          func: InteractionService.fetchAllPendingInvitations,
          query: queryString + defaultQueryString,
        }}
        itemsPerLine={itemsPerLine}
      />
    </Grid>
  );
};

export default ReportList;
