import {
  Divider,
  Paper,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Box from "@mui/material/Box";
import DateConstant from "core/constants/DateConstant";
import {
  formatToSmartTime,
  formatUserDateToTime,
  getFormattedTimezone,
} from "core/utils/dateHandler";
import { AccountContext } from "modules/Account/context";
import { AccountQuery, DayLabels, Days } from "modules/Account/models";
import AccountService from "modules/Account/services";
import React from "react";
import { useQuery } from "react-query";
import Loader from "ui-kit/components/Loader";

const is24Format = DateConstant.LOCALE_24H;

const sizes = {
  card: is24Format ? 280 : 300,
  day: is24Format ? 96 : 80,
  time: is24Format ? 90 : 130,
};

const DisplaySchedule = (): React.ReactElement | null => {
  const theme = useTheme();

  const {
    account: { id: accountId },
  } = React.useContext(AccountContext);

  const { data, error, isLoading } = useQuery(
    [AccountQuery.account, accountId],
    async () => {
      try {
        const response = await AccountService.fetchAccount(accountId);
        return response.data;
      } catch (err) {
        throw new Error(String(err));
      }
    },
    {
      keepPreviousData: true,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      enabled: !!accountId,
    }
  );

  const desktop = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"));

  if (isLoading) {
    return <Loader minHeight={100} />;
  }

  if (!data || error) {
    return null;
  }

  return (
    <Paper
      variant="outlined"
      sx={{ maxWidth: desktop ? sizes.card : "100%", mb: 4 }}
    >
      <Box px={4} py={3}>
        <Typography
          variant="body2"
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {getFormattedTimezone(data.time_zone)}
        </Typography>
      </Box>
      <Divider light />
      <Box px={3} py={2}>
        {data.work_times &&
          Object.keys(data.work_times).map((key) => {
            const keyName = key as Days;
            const dayObj = data.work_times[keyName];
            return (
              <Box
                key={key}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: 2,
                }}
              >
                <Typography variant="body2" sx={{ width: sizes.day }}>
                  {DayLabels[keyName]}
                </Typography>
                <Box
                  sx={{
                    backgroundColor: theme.app.palette.action.main,
                    height: "1px",
                    width: 14,
                    borderRadius: 1,
                  }}
                ></Box>
                <Typography
                  variant="caption"
                  color="textPrimary"
                  sx={{
                    padding: theme.spacing(0, 0.5),
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: sizes.time,
                    ...(dayObj.active && {
                      backgroundColor: theme.palette.background.default,
                    }),
                    borderRadius: 1,
                  }}
                >
                  {dayObj.active
                    ? `${formatToSmartTime(dayObj.start_time)} -
                  ${formatToSmartTime(dayObj.end_time)}`
                    : "Not active"}
                </Typography>
              </Box>
            );
          })}
      </Box>
    </Paper>
  );
};

export default DisplaySchedule;
